.App {
  max-width: 100vw;
  max-height: 100vh;
}

.Map {
  height: 100vh;
}

.mapboxgl-marker,
.legend-box {
  cursor: pointer;
}

.legend-header h4 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.legend-box {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #ffffff;
  padding: 0 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  opacity: 0.8;
  transition: height 0.5s ease;
  border: 6px double black;
}

.legend-box.collapsed {
  height: 50px;
  overflow: hidden;
}

.legend-entry {
  display: flex;
  align-items: center;
}

.legend-square {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  padding: 0.5px;
  border: 1px solid black;
}

.legend-name {
  flex: 1;
}

.legend-count {
  text-align: right;
  padding-left: 10px;
}
